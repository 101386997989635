<!--
 * @Description: 上传头像、logo、icon等
 * @Author: 琢磨先生
 * @Date: 2022-05-13 15:47:15
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-26 12:02:37
-->

<template>
  <el-upload
    v-loading="loading"
    class="avatar-uploader"
    action=""
    :limit="1"
    :show-file-list="false"
    :http-request="upload"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
  </el-upload>
</template>

<script>
// import ObsClient from "../../obs/esdk-obs-browserjs.3.22.3.min.js";
import ObsClient from "esdk-obs-browserjs/src/obs";

export default {
  data() {
    return {
      loading: false,
      imageUrl: null,
    };
  },
  emits: ["change"],
  props: ["url"],
  watch: {
    url: {
      handler(val) {
        this.imageUrl = val;
      },
      immediate: true,
    },
  },
  created() {
    var settings = this.$store.getters.getSettings;
    this.bucketName = settings.obs_bucket_name;
    this.obs_cdn = settings.obs_cdn;
    this.obs = new ObsClient({
      access_key_id: settings.obs_key_id,
      secret_access_key: settings.obs_access_key,
      server: settings.obs_server,
      timeout: 60 * 5,
    });
  },
  methods: {
    /**
     * 执行上传
     */
    upload(ctx) {
      this.loading = true;
      var ext = `.${ctx.file.type.replace("image/", "")}`;
      this.$http.get("/common/filename").then(async (res) => {
        if (res.code == 0) {
          var result = await this.obs.putObject({
            Bucket: this.bucketName,
            Key: res.data + ext,
            SourceFile: ctx.file,
          });
          if (result.CommonMsg.Status < 300) {
            this.loading = false;
            this.imageUrl = `${this.obs_cdn}/${res.data}${ext}`;
            this.$emit("change", this.imageUrl);
          }
        }
      });
    },
    /**
     * 上传成功
     */
    handleAvatarSuccess(res) {
      console.log(res);
    },
    /**
     * 上传前
     */
    beforeAvatarUpload() {},
  },
};
</script>


<style scoped>
.avatar-uploader{
  display: inline-block;
}
.avatar-uploader .avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  text-align: center;
}
</style>